import clsx from "clsx";
import { Link } from "react-router-dom";
import { default as FastMarquee } from "react-fast-marquee";
import { routesFlat } from "@routes";

function Marquee({ children, speed = 100 }) {
    return (
        <FastMarquee className="w-screen" speed={speed}>
            {children}
        </FastMarquee>
    );
}

function DotConnector() {
    return (
        <svg
            width="33"
            height="7"
            viewBox="0 0 33 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M27.3636 3.54688C27.3636 5.01963 28.5575 6.21354 30.0303 6.21354C31.503 6.21354 32.6969 5.01963 32.6969 3.54688C32.6969 2.07412 31.503 0.880208 30.0303 0.880208C28.5575 0.880208 27.3636 2.07412 27.3636 3.54688ZM0.299805 4.04688H30.0303V3.04688H0.299805V4.04688Z"
                fill="black"
            />
        </svg>
    );
}

export default function LatestMarquee() {
    return (
        <div className={parentContainerStyle}>
            <div className={latestTextStyle}>NEW PRODUCT</div>
            <div className="flex items-center justify-center lg:w-4/5 w-1/2 md:w-2/3">
                <Marquee speed={50}>
                    <div className={articlesContainerStyle}>
                        <Link
                            className="mx-4 flex items-center"
                            to={routesFlat.directActivityMap}
                        >
                            <div className="mr-4 font-bold tracking-wide">
                                REDD+AI
                            </div>
                            <DotConnector />
                            <div className="ml-4 font-thin">
                                Access the world’s first system to measure
                                forest degradation across the tropics.
                            </div>
                        </Link>
                    </div>
                </Marquee>
            </div>
        </div>
    );
}

const latestTextStyle = clsx("w-1/2 lg:w-1/5 md:w-1/3 px-4 py-2 text-right text-lg");
const articlesContainerStyle = clsx(
    "flex w-full items-center justify-between text-xs",
);
const parentContainerStyle = clsx(
    "flex bg-blue uppercase text-[black] w-screen overflow-hidden",
);

// fn PROPS
// articles: Array<Object>
// [{ title: String, date: String | date, link: String }]
