import { Helmet } from "react-helmet";
import { logos } from "@helpers/constants";

export default function MetaTagHeader({
    title,
    desc,
    imgLink = logos.color,
    imgAlt = "CTrees logo of a multicolored leaf",
}) {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <meta name="google" content="nositelinkssearchbox" />
            <title>{title}</title>
            <meta name="description" content={desc} />
            <meta property="og:image" content={imgLink} />
            <meta property="og:image:secure_url" content={imgLink} />
            <meta property="og:image:type" content="image/jpg" />
            <meta property="og:image:width" content="400" />
            <meta property="og:image:height" content="300" />
            <meta property="og:image:alt" content={imgAlt} />
            <script
                type="text/javascript"
                innerHTML={`(function (h, o, t, j, a, r) {
  h.hj =
      h.hj ||
      function () {
          (h.hj.q = h.hj.q || []).push(arguments);
      };
  h._hjSettings = { hjid: 5166999, hjsv: 6 };
  a = o.getElementsByTagName("head")[0];
  r = o.createElement("script");
  r.async = 1;
  r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
  a.appendChild(r);
})(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
`}
            ></script>
        </Helmet>
    );
}
