import * as React from "react";
import { Routes, Route } from "react-router-dom";
import {
    Landing,
    About,
    Contact,
    Luca,
    LucaMap,
    JMRV,
    JMRVMap,
    Activity,
    ActivityMap,
    Carbon,
    TreeLevel,
    Terms,
    Privacy,
    News,
    Article,
    CMS,
    NotFound,
    // UseCases,
    // Careers,
} from "./pages";
import Login from "./components/Login";
import AuthCallback from "./components/AuthCallback";

export const routesFlat = {
    landing: "/",
    luca: "/products/luca",
    lucaMap: "/products/luca/map",
    directLucaMap: "/luca",
    carbon: "/products/land-carbon",
    tree_level: "/products/tree-level",
    jmrv: "/products/jmrv",
    jmrvMap: "/products/jmrv/map",
    directJMRVMap: "/jmrv",
    activity: "/products/reddai",
    activityMap: "/products/reddai/map",
    directActivityMap: "/reddai",
    // useCases: "/use-cases",
    about: "/about",
    news: "/news",
    contact: "/contact",
    careers: "/careers",
    terms: "/terms-of-use",
    privacy: "/privacy-policy",
    admin: "/admin",
    adminNewsEdit: "/admin/news/edit",
    adminNewsDisplay: "/admin/news/view",
    youtube: "https://www.youtube.com/channel/UCUUkj-U_u53Nu9zbFR4RUeg",
    linkedin: "http://linkedin.com/company/ctrees",
    treeLevelAWSRegistry:
        "https://registry.opendata.aws/ctrees-california-vhr-tree-height/",
    notFound: "*",
    login: "/login",
    callback: "/callback",
};

export const routes = [
    {
        display: "Home",
        path: routesFlat.landing,
        element: <Landing />,
        displayInNav: true,
    },
    {
        display: "Products",
        displayInNav: true,
        nested: [
            {
                display: "Land Carbon Map",
                path: routesFlat.carbon,
                element: <Carbon />,
            },
            {
                display: "REDD+AI",
                path: routesFlat.activity,
                element: <Activity />,
                nested: [
                  {
                      display: "",
                      path: routesFlat.activityMap,
                      element: <ActivityMap />,
                  },
              ],
            },
            {
                display: "Jurisdictional MRV",
                path: routesFlat.jmrv,
                element: <JMRV />,
                nested: [
                    {
                        display: "",
                        path: routesFlat.jmrvMap,
                        element: <JMRVMap />,
                    },
                ],
            },
            {
                display: "Land Use Change Alerts",
                path: routesFlat.luca,
                element: <Luca />,
                displayInNav: true,
                nested: [
                    {
                        display: "",
                        path: routesFlat.lucaMap,
                        element: <LucaMap />,
                    },
                ],
            },
            {
                display: "Tree-Level Data",
                path: routesFlat.tree_level,
                element: <TreeLevel />,
            },
        ],
    },
    // {
    //   display: "Use Cases",
    //   path: "/use-cases",
    //   element: <UseCases />,
    //   displayInNav: true,
    // },
    {
        display: "",
        path: routesFlat.directLucaMap,
        element: <LucaMap />,
        displayInNav: false,
    },
    {
        display: "",
        path: routesFlat.directJMRVMap,
        element: <JMRVMap />,
        displayInNav: false,
    },
    {
        display: "",
        path: routesFlat.directActivityMap,
        element: <ActivityMap />,
        displayInNav: false,
    },
    {
        display: "About",
        path: routesFlat.about,
        element: <About />,
        displayInNav: true,
    },
    {
        display: "News",
        path: routesFlat.news,
        element: <News />,
        displayInNav: true,
    },
    {
        display: "News",
        path: routesFlat.news + "/:id",
        element: <Article />,
        displayInNav: false,
    },
    // {
    //   display: "Careers",
    //   path: "/careers",
    //   element: <Careers />,
    //   displayInNav: true,
    // },
    {
        display: "Contact",
        path: routesFlat.contact,
        element: <Contact />,
        displayInNav: true,
    },
    {
        display: "Admin",
        path: routesFlat.admin,
        element: (
            <Login>
                <CMS />
            </Login>
        ),
        displayInNav: false,
    },
    {
        display: "Terms of Use",
        path: routesFlat.terms,
        element: <Terms />,
        displayInNav: false,
    },
    {
        display: "Privacy Policy",
        path: routesFlat.privacy,
        element: <Privacy />,
        displayInNav: false,
    },
    {
        display: "404",
        path: "*",
        element: <NotFound />,
        displayInNav: false,
    },
    {
        display: "Login",
        path: routesFlat.login,
        element: <Login />,
        displayInNav: false,
        requireAuth: true,
    },
    {
        display: "Callback",
        path: routesFlat.callback,
        element: <AuthCallback />,
        displayInNav: false,
        requireAuth: false,
    },
];

export const navRoutes = routes.filter((route) => route.displayInNav);

const RoutesMap = (routes) => {
    return routes.map(
        ({ path, element, nested = null, requireAuth = false }, i) => {
            if (requireAuth) {
                return (
                    <Route
                        path={path}
                        key={path}
                        element={element}
                        requireAuth={true}
                    />
                );
            }
            if (nested || path === routesFlat.landing) {
                return (
                    <Route key={path + i} path={path}>
                        <Route index={true} element={element} />
                        {nested && RoutesMap(nested)}
                    </Route>
                );
            }

            return <Route path={path} key={path} element={element} />;
        },
    );
};
export default function RoutesComp() {
    return <Routes>{RoutesMap(routes)}</Routes>;
}
