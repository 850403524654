// modify the title and desc attrs
export const header = {
  title: "REDD+AI",
  desc: "See forest degradation across the tropics.",
  img: "https://ctrees-website.s3.amazonaws.com/website_images/product/activity_pg.jpg",
};

// for each feature, add as an object item with title and desc attr inside of featsAndBenefits array
export const featsAndBenefits = [
  {
    title: "Available now",
    desc: "Datasets mapping forest degradation are available through the REDD+AI platform.",
  },
  {
    title: "Tropical coverage",
    desc: "See data for the world’s tropical moist forests.",
  },
  {
    title: "Precise attributions",
    desc: "First-of-its-kind AI technology identifies forest degradation from logging, fire, and road construction with >90% overall accuracy.",
  },
  {
    title: "Historical perspective",
    desc: "Analyze trends at 5-meter resolution for 2017 to 2023.",
  },
];

// for each paragraph in methodology description, add as string item inside of method array
export const desc = [
  "The REDD+AI platform provides open access for visualization of CTrees’ maps of stable forest cover, tree cover loss, and forest degradation, with attribution to logging, forest fire, and road construction. Data available cover the tropical moist forests biome at 4.77-meter resolution for 2017-2023. The platform offers summary statistics for all countries and their subnational jurisdictions where data are available.",
  "The 4.77-meter data with attributions correspond to CTrees maps of tree cover loss and degradation based on images from the PlanetScope satellite constellation, provided by Planet Labs PBC and made available via Norway’s International Climate and Forests Initiative (NICFI). To produce the data, CTrees trained and applied AI models to the satellite images, as described in peer-reviewed publications [1][2]. The dataset covers the period of 2017 to present, at biannual to monthly time scales.",
  "The datasets offer unprecedented spatial and temporal resolution. CTrees produces degradation layers data bi-annually (twice per year) from 2016 to present, and tree cover loss bi-annually (twice per year) from 2017 to June 2020, and monthly from September 2020 to present. At present, the REDD+AI platform displays data at annual time scales. 2024 data will be released in the next version.",
  "These data are the first pantropical dataset to attribute areas of forest degradation to their specific drivers: logging, forest fire, and road construction. Pixels experiencing complete tree cover removal were defined as loss, and areas affected by disturbances from logging (including both legal or illegal logging), forest fire, or road construction were mapped separately and can depict potentially degraded forests. An Algorithm Theoretical Basis Documents (ATBD) provides additional detail [3].",
  "The frequency of the Planet NICFI data changed in September 2020 from biannual to monthly. We can expect data to spike in 2020 as more tree cover loss events are picked up. For further limitations, please reference the Algorithm Theoretical Basis Documents (ATBD).",
  "In addition to REDD+AI data, CTrees has produced pantropical maps of deforestation and secondary forest regeneration from 1990 to present based on NASA’s Landsat imagery at 30-meter resolution. The methodology is based on available data and resources on Google Earth Engine, machine learning algorithms developed by CTrees research scientists [4], and extensive validation of maps using statistical samples from high-resolution images and local expertise. Advantages of CTrees activity data are the long time series and accurate attributions of land use activities. Specifically the dataset detects area and the age of forest regeneration due to land abandonment, restoration, and afforestation. The maps are currently available to CTrees partners and will be added to REDD+AI in 2025.",
];
// for each numbered reference under methodology, add as string item inside of superscript array
export const superscript = [
  '[1] Wagner, Fabien H., et al. 2023. "Mapping Tropical Forest Cover and Deforestation with Planet NICFI Satellite Images and Deep Learning in Mato Grosso State (Brazil) from 2015 to 2021" Remote Sensing 15, no. 2: 521. https://doi.org/10.3390/rs15020521',
  '[2] Dalagnol, R. et al. 2023. Mapping tropical forest degradation with deep learning and Planet NICFI data” Remote Sensing of Environment, Vol. 298 https://doi.org/10.1016/j.rse.2023.113798',
  "[3] Algorithm Theoretical Basis Documents (ATBD). https://ctrees-website.s3.us-west-2.amazonaws.com/product/REDD%2BAI.pdf",
  "[4] Silva Junior, C. H., Heinrich, V. H., Freire, A. T., Broggio, I. S., Rosan, T. M., Doblas, J., ... & Aragão, L. E. (2020). Benchmark maps of 33 years of secondary forest age for Brazil. Scientific data, 7(1), 269."
];
