const bucketRoot =
  "https://ctrees-website.s3.us-west-2.amazonaws.com/website_images";
export const logos = {
  color: `${bucketRoot}/logos/color-logo.svg`,
  black: `${bucketRoot}/logos/black-logo.svg`,
  withText: `${bucketRoot}/logos/logo-with-text.png`,
  blackWithText: `${bucketRoot}/logos/black-logo-text.svg`,
};
export const partners = {
  ageos: `${bucketRoot}/partners/ageos.jpeg`,
  aso: `${bucketRoot}/partners/aso.jpeg`,
  cool: `${bucketRoot}/partners/cool.jpeg`,
  crosswalk: `${bucketRoot}/partners/crosswalk.jpeg`,
  taskforce: `${bucketRoot}/partners/taskforce.jpeg`,
  terra: `${bucketRoot}/partners/terra.jpeg`,
  trace: `${bucketRoot}/partners/trace.jpeg`,
  vp: `${bucketRoot}/partners/vp.jpeg`,
  cll: `${bucketRoot}/partners/cll.jpg`,
  fs: `${bucketRoot}/partners/usforestservice.jpg`,
};
export const blobs = {
  1: `${bucketRoot}/leaf-blobs/1.svg`,
  2: `${bucketRoot}/leaf-blobs/2.svg`,
  3: `${bucketRoot}/leaf-blobs/3.svg`,
  4: `${bucketRoot}/leaf-blobs/4.svg`,
  5: `${bucketRoot}/leaf-blobs/5.svg`,
  6: `${bucketRoot}/leaf-blobs/6.svg`,
  7: `${bucketRoot}/leaf-blobs/7.svg`,
  8: `${bucketRoot}/leaf-blobs/8.svg`,
  9: `${bucketRoot}/leaf-blobs/9.svg`,
  10: `${bucketRoot}/leaf-blobs/10.svg`,
  11: `${bucketRoot}/leaf-blobs/11.svg`,
};
export const overlayBlocks = {
  1: `${bucketRoot}/static/overlay/1.svg`,
  2: `${bucketRoot}/static/overlay/2.svg`,
  3: `${bucketRoot}/static/overlay/3.svg`,
  4: `${bucketRoot}/static/overlay/4.svg`,
  5: `${bucketRoot}/static/overlay/5.svg`,
};
export const heatmaps = {
  1: `${bucketRoot}/video/heatmaps-1.mp4`,
  2: `${bucketRoot}/video/heatmaps-2.mp4`,
  3: `${bucketRoot}/video/heatmaps-3.mp4`,
};
export const staticTree = `${bucketRoot}/static/static-tree.svg`

const productRoutes = {
  luca: "/products/luca",
  lucaMap: "/products/luca/map",
  carbon: "/products/land-carbon",
  treeMap: "/products/tree-level",
  jmrv: "/products/jmrv",
  activity: "/products/reddai",
};

export const productList = [
  {
    title: "Land Carbon Map",
    desc: "Measure carbon in all forest and non-forest land globally",
    btnLink: productRoutes.carbon,
    imgURL: "https://ctrees-website.s3.amazonaws.com/website_images/product/carbon.jpg",
    imgLeft: true,
  },
  {
    title: "REDD+AI",
    desc: "See deforestation, forest degradation, and forest regeneration across the tropics",
    btnLink: productRoutes.activity,
    imgURL: "https://ctrees-website.s3.amazonaws.com/website_images/product/activity.jpg",
    imgLeft: false,
  },
  {
    title: "Jurisdictional MRV",
    desc: "Measure, report, and verify carbon stocks, emissions, and removals in all jurisdictions globally",
    btnLink: productRoutes.jmrv,
    imgURL: "https://ctrees-website.s3.amazonaws.com/website_images/product/jmrv.jpg",
    imgLeft: true,
  },
  {
    title: "Land Use Change Alerts (LUCA)",
    desc: "Get near-real-time accurate information on land use change activities and forest disturbance worldwide",
    btnLink: productRoutes.luca,
    imgURL: "https://ctrees-website.s3.amazonaws.com/website_images/product/luca.jpg",
    imgLeft: false,
  },
  {
    title: "Tree-Level Data",
    desc: "Map individual trees at local to national scales",
    btnLink: productRoutes.treeMap,
    imgURL: "https://ctrees-website.s3.amazonaws.com/website_images/product/treelevel.jpg",
    imgLeft: true,
  },
];

export const beTagsFlat = {
  luca: 'luca',
  jmrv: 'jmrv',
  activity: 'activity',
  carbon: 'land_carbon',
  treeLevel: 'tree_level',
  specialEvent: 'special_event',
}
export const tags = {
  product: {
    luca: 'LUCA',
    jmrv: 'JMRV',
    land_carbon: 'Land Carbon',
    tree_level: 'Tree Level',
    activity: 'Activity Data',
    none: 'All',
  },
  type: {
    special_event: 'Special Event',
    webinar: 'Webinar',
    news: 'In The News',
    event: 'Event',
    press_release: 'Press Release',
    blog: 'Blog',
    none: 'All',
  }
}